/* global $ */

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import "jquery-bootstrap-scrolling-tabs/dist/jquery.scrolling-tabs.js";
import "jquery-bootstrap-scrolling-tabs/dist/jquery.scrolling-tabs.css";

class App {
    bootstrap;

    constructor() {
        this.menuMoving = false;
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initPopover();
        this.initForms();
        this.initFontAwesome();
        this.initCarousel();
        this.initMatrixContent();
        this.initStickyMenu();
        this.initMenu();
        this.initCard();
        this.initNavTab();
        this.initSidebarMenu();
        this.initPagination();
        this.initAccordion();
        this.initKeyLinks();
        this.initSelect2();
        this.initSVGInjector();
        this.initReadmore();

        $('body').css('opacity', 1);
        /**
         * trigger a page reload when changing select value
         */
        $(document).ready(() => {
            $('.tab-text .nav-tabs').scrollingTabs({
                bootstrapVersion: 5,
                scrollToTabEdge: true,
                enableSwiping: true,
                disableScrollArrowsOnFullyScrolled: true,
                leftArrowContent: `
                  <div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-left">
                    <button class="scrtabs-arrow-btn scrtabs-click-target" type="button">
                        <i class="fa-solid fa-angle-left"></i>
                    </button>
                  </div>`,
                rightArrowContent: `
                  <div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-right">
                    <button class="scrtabs-arrow-btn scrtabs-click-target" type="button">
                        <i class="fa-solid fa-angle-right"></i>
                    </button>
                  </div>`
            }).on('ready.scrtabs', () => {
                $('.nav-link').click(() => {
                    setTimeout(() => {
                        $('.nav-tabs').scrollingTabs('scrollToActiveTab');
                    }, 10);
                });
            });

            $('.dropdown-menu').on('click', function (e) {
                if ($(this).hasClass('dropdown-menu-search')) {
                    e.stopPropagation();
                }
            });
        });

        $('.triggerQueryParam').change(function () {
            const _param = $(this).data('param'),
                _value = $(this).val(),
                _url = new URL(window.location.href);

            if (_param) {
                if (_value) {
                    _url.searchParams.set(_param, _value);
                } else {
                    _url.searchParams.delete(_param);
                }
                location.href = _url.toString();
            }
        });

        Fancybox.bind('[data-fancybox="gallery"]', {
            Toolbar: {
                display: {
                    left: ["infobar"],
                    middle: [],
                    right: ["close"],
                },
            },
        });
    }

    initSelect2() {
        if ($('.form-select2').length) {
            import(/* webpackChunkName: "select2" */ './components/select2').then(() => {
                $('.form-select2').each(function () {
                    $(this).select2({
                        width: 'style',
                        dropdownPosition: 'below',
                    });
                });
            });
        }
        $(document).on('select2:open', () => {
            document.querySelector('.select2-search__field').focus();
        });
        $(document).on('select2:close', (e) => {
            window.dispatchEvent(new CustomEvent('select2:close', { 'detail': e }));
        });
    }

    initSidebarMenu() {
        const sidebarMenu = $('.textSidebarMenu');
        if (sidebarMenu.length) {
            const currentPath = window.location.pathname,
                menuLink = sidebarMenu.find('a[href*="' + currentPath + '"]');

            if (menuLink) {
                this.getBootstrap().then(() => {
                    const tabPane = menuLink.parents('.tab-pane'),
                        tabId = tabPane.attr('id'),
                        tabEl = sidebarMenu.find('button[data-bs-target="#' + tabId + '"]');

                    tabEl.trigger('click');
                });
            }
        }
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }

    initMenu() {
        if ($('.employer-sidebar').length) {
            var myOffcanvas = document.getElementById('employerSidebar')
            myOffcanvas.addEventListener('show.bs.offcanvas', function () {
                $('.menu-toggler').removeClass("collapsed");
            })
            myOffcanvas.addEventListener('hide.bs.offcanvas', function () {
                $('.menu-toggler').addClass("collapsed");
            })
        }
    }

    initCarousel() {
        if ($('.slick-carousel, .featured-list, .sitejobs, .ad-carousel, .review-slider, .cta-slider, .ad-logo-carousel, .gallery-slider, .tile-slider, .tabbed-slider, .related-jobs, .video-playlist-carousel ').length) {
            import(/* webpackChunkName: "carousel" */ './components/carousel');
        }
    }

    initCard() {
        if ($('.card').length) {
            import(/* webpackChunkName: "card" */ './components/card');
        }
    }

    initKeyLinks() {
        if ($('.keyLinks-section').length) {
            import(/* webpackChunkName: "keyLinks" */ './components/keyLinks');
        }
    }

    initNavTab() {
        if ($('.nav-tabs, .nav-pills').length) {
            import(/* webpackChunkName: "tab" */ './components/tab');
        }
    }

    initAccordion() {
        if ($('.accordion').length) {
            import(/* webpackChunkName: "accordion" */ './components/accordion');
        }
    }

    initPagination() {
        if ($('.pagination').length) {
            import(/* webpackChunkName: "pagination" */ './components/pagination');
        }

        $('#directoryPagination').change(function () {
            if ('URLSearchParams' in window) {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('page', $(this).val());
                window.location.search = searchParams.toString();
            }
        });
    }

    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone, .sortable').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initSVGInjector() {
        if ($('.inject-me').length) {
            import(/* webpackChunkName: "svgInjector" */ './components/svgInjector').then(SVGInjector => {
                var mySVGsToInject = document.querySelectorAll('img.inject-me');
                SVGInjector.SVGInjector(mySVGsToInject);
            });
        }
    }

    initReadmore() {
        var readMore = function () {
            const readmoreContent = $('#readmoreContent');
            const readmoreButton = $('#readmoreButton');
            const readmoreContentHeight = readmoreContent.height();
            if (readmoreContentHeight > 230) {
                readmoreButton.show();
                readmoreContent.addClass("readmore");
            } else {
                readmoreButton.hide();
                readmoreContent.removeClass("readmore");
            }

            $(readmoreButton).click(function () {
                $(readmoreContent).toggleClass("open");
                if ($(readmoreButton).text() == "Read More") {
                    $(this).text("Read Less");
                    $(this).addClass("less");
                    $(this).removeClass("more");
                } else {
                    $(this).text("Read More");
                    $(this).addClass("more");
                    $(this).removeClass("less");
                }

                let $panel = $(this).closest('.contentTitle');
                $('html,body').animate({
                    scrollTop: $panel.offset().top - 80
                }, 400);
            });
        };
        readMore();
    }

    initForms() {
        if ($('form, .form-check-input').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form'), (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"), (i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initToasts(elems) {
        this.getBootstrap().then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem).show();
            });
        });
    }

    initTooltips(elems) {
        this.getBootstrap().then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initPopover() {
        this.getBootstrap().then(bootstrap => {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            popoverTriggerList.map(function (popoverTriggerEl) {
                new bootstrap.Popover(popoverTriggerEl)
            })
        });
    }

    addToast(message, type = "success", autoHide = true) {
        let _class = "align-items-center text-white border-0 mt-2 toast bg-" + type;
        let toast = $('<div class="' + _class + '" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="' + (autoHide ? 'true' : 'false') + '"><div class="d-flex"><div class="toast-body">' + message + '</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
        toast.appendTo($('#global-messages'));
        this.initToasts(toast);
    }

    showErrors(form, errors) {
        let $elem;
        form.find('.invalid-feedback').remove();
        for (let [name, list] of Object.entries(errors)) {
            $elem = form.find('.field-' + name);
            for (let error of list) {
                $elem.append('<div class="invalid-feedback">' + error.replace(/[*]/g, '') + '</div>');
            }
        }
    }

    initMatrixContent() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrix-content" */ './components/matrixContent');
        }
    }
}

export default App;
